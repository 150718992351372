<template>
  <div class="teacher">
    <list-template
        :current-page="page"
        :loading="loading"
        :search-config="searchConfig"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        hasAdd
        @onAdd="onAdd"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
        @onReset="search = null"
        @onSearch="onSearch"
    ></list-template>
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" width="500px">
      <el-form v-if="title==='新增'" label-position="left" label-width="100px">
        <el-form-item label="分组名称">
          <el-select v-if="title==='新增'" v-model="data.group_id" placeholder="请选择分组名称" @change="change">
            <el-option v-for="item in options[0]" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名">
          <el-select
              v-model="data.teacher_id"
              :disabled="!data.group_id"
              :remote-method="remoteMethod"
              filterable
              placeholder="请输入姓名"
              remote
              reserve-keyword @change="$forceUpdate()">
            <el-option
                v-for="item in options[1]"
                :key="item.id"
                :label="`${item.clerk_name}(${item.mobile})`"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div style="margin-top: 128px">
          <el-button :loading="loadingBtn" type="primary" @click="onConfirm">保存</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </div>
      </el-form>
      <div v-if="title==='解绑提醒'" style="width: 100%;text-align: center">
        <div style="margin-top: 48px">解绑后，此用户在组内的功能将无法使用</div>
        <div style="margin-top: 128px">
          <el-button :loading="loadingBtn" type="primary" @click="onConfirm">继续解绑</el-button>
          <el-button @click="dialogVisible = false">点错了</el-button>
        </div>
      </div>
      <div v-if="title==='删除提醒'" style="width: 100%;text-align: center">
        <div style="margin-top: 48px">确定删除此人员当前分组?</div>
        <div style="margin-top: 128px">
          <el-button :loading="loadingBtn" type="primary" @click="onConfirm">继续删除</el-button>
          <el-button @click="dialogVisible = false">点错了</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  _config:{
    route:{
      path:"list",
      meta:{
        title:"列表"
      }
    }
  },
  data() {
    return {
      loading: true,
      searchConfig: [
        {
          prop: 'teacher',
          placeholder: '搜索教师姓名',
        },
      ],
      tableConfig: [
        {
          prop: 'teacher',
          label: '教师姓名'
        },
        {
          prop: 'group_name',
          label: '分组名称'
        },
        {
          prop: 'creator',
          label: '创建人'
        },
        {
          prop: 'created_at',
          label: '创建时间'
        },
        {
          prop: 'teacher_status',
          label: '教师状态'
        },
        {
          label: '操作',
          handle: true,
          width: 120,
          render: (row) => Number(row?.status) === 0 ? ['解绑'] : ['删除']
        }
      ],
      search: null,
      total: 0,
      tableData: [],
      dialogVisible: false,
      loadingBtn: false,
      title: '',
      data: {},
      options: [[], []],
      id: null
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  activated() {
    this.getData()
  },
  methods: {
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('api/attendance/group-personnel/list', {params: {...search, page}}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false);
    },
    onAdd() {
      this.title = '新增';
      this.dialogVisible = true;
      this.data = {}
    },
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    tableHandle(row, text) {
      if (row.status === 0) {
        this.title = '解绑提醒';
      } else {
        this.title = '删除提醒';
      }
      this.dialogVisible = true;
      this.id = row.id
    },
    onConfirm() {
      if (this.title === '新增') {
        if (!this.data.group_id)
          return this.$message.error('所属分组不能为空');
        if (!this.data.teacher_id)
          return this.$message.error('教师姓名不能为空');
        this.loadingBtn = true
        this.$_axios2.post('api/attendance/group-personnel/store', this.data).then((res) => {
          if (res.data.status !== 0) return
          this.$store.commit('setPage', 1)
          this.getData();
          this.dialogVisible = false
          this.$message.success('新增成功');
        }).finally(() => this.loadingBtn = false)
      }
      if (this.title === '解绑提醒') {
        this.loadingBtn = true
        this.$_axios2.get(`api/attendance/group-personnel/unbind`, {params: {id: this.id}}).then((res) => {
          if (res.data.status !== 0) return
          this.getData();
          this.dialogVisible = false
          this.$message.success('解绑成功');
        }).finally(() => this.loadingBtn = false)
      }
      if (this.title === '删除提醒') {
        this.loadingBtn = true
        this.$_axios2.get(`api/attendance/group-personnel/destroy`, {params: {id: this.id}}).then((res) => {
          if (res.data.status !== 0) return
          this.$store.commit('setPage', 1)
          this.getData();
          this.dialogVisible = false
          this.$message.success('删除成功');
        }).finally(() => this.loadingBtn = false)
      }
    },
    remoteMethod(val) {
      this.$_axios2.get('api/common/screen/search-teacher', {params: {keyword: val, school_id: this.options[0].filter(item => item.id === this.data.group_id)[0]?.school_id, type: 0}}).then(res => {
        this.options[1] = res.data.data;
        this.$forceUpdate()
      })
    },
    change() {
      this.options[1] = []
      this.data.teacher_id = ''
    },
  },
  created() {
    this.$_axios2.get('api/attendance/group/linkage').then(res => {
      this.options[0] = res.data.data
    })
  }
}
</script>

<style lang="scss" scoped>
.list {
  padding: 24px 0 0 32px;

  .search {
    .el-input,
    .el-select {
      width: 240px;
      margin-right: 24px;
    }
  }

  .pagination {
    margin-top: 50px;
  }
}
</style>
